import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ANALYSIS,
  ANALYSISDETAILS,
  ANALYSISTAG,
  WAVEFILE,
  getAnalysisAction,
  getAnalysisDetailsAction,
  getWaveFileAction,
  postAnalysisTagAction,
} from '../actions/analysisAction';
import {
  getAnalysisByUserService, getAnalysisService, getWavefileService, postAnalysisTagService,
} from '../services/analysisService';

export const selectGetAnalysisService = (role) => (
  role !== 1 ? getAnalysisByUserService : getAnalysisService
);

export function* getAnalysis({ payload }) {
  const { token, role } = payload;
  const service = selectGetAnalysisService(role);
  try {
    const analysis = yield call(service, token);
    const data = analysis?.data;
    if (data) {
      yield put(getAnalysisAction.success(data));
    } else yield put(getAnalysisAction.error("Pas d'analyses disponibles"));
  } catch (err) {
    yield put(getAnalysisAction.failure(err));
  }
}

export function* postAnalysisTag({ payload }) {
  const { token, role } = payload;
  const service = selectGetAnalysisService(role);

  try {
    const analysisResponse = yield call(postAnalysisTagService, payload);

    if (analysisResponse) {
      const analysis = yield call(service, token);
      yield put(getAnalysisAction.success(analysis.data));
    }
  } catch (err) {
    yield put(postAnalysisTagAction.failure(err));
  }
}

const getAgencyDetailsByName = (detailsByAgency, agencyName) => {
  let agencyExist = detailsByAgency.get(agencyName);

  if (!agencyExist) {
    agencyExist = {
      name: agencyName,
      details: new Map(),
    };
    detailsByAgency.set(agencyName, agencyExist);
  }
  return agencyExist;
};

const getMobileDetails = (agencyData, telephoneNumber) => {
  let mobile = agencyData.details.get(telephoneNumber);

  if (!mobile) {
    mobile = {
      telephoneNumber,
      leak: [],
      noLeak: [],
      qualificationOK: [],
      qualificationNOK: [],
      noSignal: [],
    };
    agencyData.details.set(telephoneNumber, mobile);
  }
  return mobile;
};

const processDecision = (mobile, decision, qualification, timeStamp) => {
  const updatedMobile = { ...mobile };

  if (decision === 1) {
    updatedMobile.leak.push(timeStamp);
  } else {
    updatedMobile.noLeak.push(timeStamp);
  }

  if (qualification === decision) {
    updatedMobile.qualificationOK.push(timeStamp);
  } else {
    updatedMobile.qualificationNOK.push(timeStamp);
  }

  return updatedMobile;
};

const calculateNoSignal = (mobile, recordsList) => {
  const allRecordsList = recordsList
    .filter((record) => record.numberMobile === mobile.telephoneNumber);
  const noSignal = allRecordsList
    .filter((record) => record.qualification === 3)
    .map((record) => record.timestamp);
  return noSignal;
};

const getAgencyAnalysis = (analysis, recordsList) => {
  const detailsByAgency = new Map();

  analysis?.forEach((analyse) => {
    const {
      agencyName, telephoneNumber, decision, qualification, timeStamp,
    } = analyse;

    const agencyData = getAgencyDetailsByName(detailsByAgency, agencyName);
    const mobile = getMobileDetails(agencyData, telephoneNumber);

    const updatedMobile = processDecision(mobile, decision, qualification, timeStamp);
    agencyData.details.set(telephoneNumber, updatedMobile);
  });

  const updatedDetailsByAgency = new Map();
  detailsByAgency.forEach((agencyData) => {
    const updatedDetails = Array.from(agencyData.details.values()).map((mobile) => {
      const noSignal = calculateNoSignal(mobile, recordsList);
      return { ...mobile, noSignal };
    });
    updatedDetailsByAgency.set(agencyData.name, { ...agencyData, details: updatedDetails });
  });

  return Array.from(updatedDetailsByAgency.values());
};

export function* getAnalysisDetails({ payload }) {
  const { analysis, recordsList } = payload;

  try {
    const agencyDetails = getAgencyAnalysis(analysis, recordsList);
    yield put(getAnalysisDetailsAction.success(agencyDetails));
  } catch (error) {
    yield put(getAnalysisDetailsAction.failure(error));
  }
}

export function* getWavefile({ payload }) {
  try {
    const base64Audio = yield call(getWavefileService, payload);
    if (base64Audio) {
      yield put(getWaveFileAction.success(base64Audio.data));
    }
  } catch (error) {
    yield put(getWaveFileAction.failure(error));
  }
}

export function* analysisSaga() {
  yield takeEvery(ANALYSISDETAILS.FETCH.REQUEST, getAnalysisDetails);
  yield takeEvery(ANALYSIS.FETCH.REQUEST, getAnalysis);
  yield takeEvery(ANALYSISTAG.POST.REQUEST, postAnalysisTag);
  yield takeEvery(WAVEFILE.FETCH.REQUEST, getWavefile);
}
