import { notification, Tag } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import fr from 'dayjs/locale/fr';
import 'dayjs/locale/es';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  StopOutlined,
  SyncOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import {
  blueAzur2Color,
  blueColor,
  blueLight2Color,
  blueLightColor,
  bluePrimaryColor,
  greenLightColor,
  greyColor,
  orangeColor,
  paleGreenColor,
  redColor,
  violetColor,
} from '../assets/styles/colors';
import { logoutAction } from '../business/actions/loginAction';
import ActionButton from '../components/shared/ActionButton';

dayjs.extend(relativeTime);
dayjs.locale(fr);

export const regexEmail = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
export const regexMDP = /^(?=.*[~@!%=+\-*?&[\]{}()'\\|`_^])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
export const regexMobileNumber = /^\d{10}$/;
export const regexNumber = /^\d+$/;
export const multipleZeros = /^0{2,}/;
export const regexCanal = /^(?!.*\s{2,})[a-zA-Z0-9](?:[a-zA-Z0-9\s]|-(?=[a-zA-Z0-9])){1,249}$/;

export const isProdEnv = process.env.REACT_APP_ENV === 'prod';

export const roles = {
  chief: 1,
  distributor: 2,
  company: 3,
  agency: 4,
};
const checkRoles = (fullRole) => {
  const uniqueRole = typeof (fullRole) === 'string';
  const roleList = [];
  if (uniqueRole) {
    const role = fullRole.split(' ')[0].toLocaleLowerCase();
    roleList.push(role);
  } else {
    fullRole.forEach((role) => {
      roleList.push(role);
    });
  }
  return roleList;
};
export const getRoleFromToken = (decodedToken) => {
  const roleKey = Object.keys(decodedToken).find((key) => key.includes('/identity/claims/role'));
  if (!roleKey) {
    return null;
  }

  const fullRole = decodedToken[roleKey];
  const rolesFromApi = checkRoles(fullRole);
  return roles[rolesFromApi[0]];
};

export const getExpFromToken = (decodedToken) => {
  const expKey = Object.keys(decodedToken).find((key) => key === 'exp');
  const expRefreshKey = Object.keys(decodedToken).find((key) => key === 'refreshExpiry');
  const now = dayjs().unix();

  const accessValue = decodedToken[expKey];
  const refreshValue = decodedToken[expRefreshKey];

  const accessExpired = (accessValue - now) < 0;
  const refreshExpired = (refreshValue - now) < 0;

  return { accessExpired, refreshExpired };
};

export const getInfosFromToken = (decodedToken) => {
  const expKey = Object.keys(decodedToken).find((key) => key === 'exp');
  const expRefreshKey = Object.keys(decodedToken).find((key) => key === 'refreshExpiry');
  const now = dayjs().unix();
  const roleKey = Object.keys(decodedToken).find((key) => key.includes('/identity/claims/role'));
  const userKey = Object.keys(decodedToken).find((key) => key.includes('/identity/claims/actor'));
  if (!roleKey) {
    return null;
  }

  const userId = decodedToken[userKey];
  const fullRole = decodedToken[roleKey];

  const rolesFromApi = checkRoles(fullRole);
  const roleFromAPI = rolesFromApi[0];
  const firstRoleFromApi = roles[roleFromAPI];

  const accessValue = decodedToken[expKey];
  const refreshValue = decodedToken[expRefreshKey];
  const accessExpired = (accessValue - now) < 0;
  const refreshExpired = (refreshValue - now) < 0;
  return {
    accessExpired, refreshExpired, role: firstRoleFromApi, userId,
  };
};

export const getQualificationValue = (qualification) => {
  switch (qualification) {
    case 0:
      return 'noLeak';
    case 1:
      return 'leak';
    case 10:
      return 'suspicious';
    default:
      return 'notQualified';
  }
};

export const getIconQualification = (qualification, decision) => {
  if (qualification === 2) {
    return <StopOutlined className="grey" />;
  }
  if (qualification === decision) {
    return <CheckOutlined className="green" />;
  }
  if (decision === 10) {
    return <CheckOutlined className="orange" />;
  }
  return <CloseOutlined className="red" />;
};

export const getLeakValue = (decision) => (decision === 1 ? 'leak' : 'noLeak');

export const optionsLanguages = [
  {
    id: 5, value: 'en', label: 'en',
  },
  {
    id: 20, value: 'fr', label: 'fr',
  },
  {
    id: 30, value: 'es', label: 'es',
  },
];

export const onSuccess = (message, description) => {
  notification.success({
    message,
    description,
  });
};

export const onError = () => {
  notification.error({
    message: 'Oops',
    description: 'Une erreur est survenue',
  });
};

export const openNotificationInfo = ({ message, description, placement }) => {
  notification.info({
    message,
    description,
    placement,
  });
};

export const renderEmails = (item, index) => <div key={item + index}>{item}</div>;

export const renderSelectValues = (state, isDisabled) => {
  const arrayOptions = [];
  state?.map((item) => {
    const { id, name } = item;
    const disabled = isDisabled ? isDisabled(item) : false;
    arrayOptions.push({ value: id, label: name, disabled });
    return null;
  });
  return arrayOptions;
};

export const monitoringComponents = [
  {
    id: 5,
    title: 'analysis',
    type: 'indicator',
    source: 'analysis',
    fill: blueLightColor,
    order: 5,
  },
  {
    id: 10,
    title: 'leak',
    type: 'indicator',
    source: 'leak',
    fill: redColor,
    order: 10,
  },
  {
    id: 15,
    title: 'suspicionLeak',
    type: 'indicator',
    source: 'suspicionLeak',
    fill: orangeColor,
    order: 15,
  },
  {
    id: 20,
    title: 'noLeak',
    type: 'indicator',
    source: 'noLeak',
    fill: bluePrimaryColor,
    order: 20,
  },
  {
    id: 21,
    title: 'unqualifiedSounds',
    type: 'indicator',
    source: 'unqualified',
    fill: violetColor,
    order: 21,
    role: roles.chief,
  },
  {
    id: 30,
    title: 'faultySignal',
    type: 'indicator',
    source: 'faultySignal',
    fill: greyColor,
    order: 30,
    role: roles.chief,
  },
  {
    id: 110,
    title: 'agencies',
    type: 'counter',
    source: 'agencies',
    order: 110,
  },
  {
    id: 120,
    title: 'companies',
    type: 'counter',
    source: 'companies',
    order: 120,
  },
  {
    id: 130,
    title: 'mobiles',
    type: 'counter',
    source: 'mobiles',
    order: 130,
  },
  {
    id: 140,
    title: 'calibratedPhones',
    type: 'counter',
    source: 'calibratedPhones',
    order: 140,
  },
  {
    id: 145,
    title: 'calibratedInProcess',
    type: 'counter',
    source: 'calibratedInProcess',
    order: 145,
  },
];

export const getSourceState = (source, state) => state[source];

export const formatDate = (timestamp, format, language) => {
  dayjs.locale(language || 'fr');
  return dayjs(timestamp).format(format || 'DD/MM/YYYY');
};

export const formatDateOrNA = (timestamp, format) => (
  timestamp ? formatDate(timestamp, format) : 0
);

export const getFieldValue = (value) => (value !== '--' ? value : '');

export const formatTimeAgo = (timestamp, language, noRecord) => {
  dayjs.locale(language);
  const diff = () => {
    if (timestamp) {
      return dayjs(timestamp).fromNow();
    }
    return noRecord;
  };
  return diff();
};

export const getDifferenceTimestamps = (startTimestamp, endTimestamp) => {
  const durationInMinutes = dayjs(endTimestamp).diff(dayjs(startTimestamp), 'minute');

  let durationText;

  if (durationInMinutes >= 1440) {
    const days = Math.floor(durationInMinutes / 1440);
    durationText = `${days} j`;
  } else if (durationInMinutes >= 60) {
    const hours = Math.floor(durationInMinutes / 60);
    durationText = `${hours} h`;
  } else {
    durationText = `${durationInMinutes} min`;
  }

  return durationText;
};

export const getTimestampsPeriod = (periodData) => {
  if (!periodData) {
    return {
      startTimestamp: null,
      expiryTimestamp: null,
    };
  }

  const startTimestamp = dayjs(periodData[0].$d).valueOf();
  const expiryTimestamp = dayjs(periodData[1].$d).valueOf();

  return {
    startTimestamp,
    expiryTimestamp,
  };
};

export const filterByMonth = (timestamps, selectedMonth) => {
  const selectedMonthFormat = formatDate(selectedMonth, 'MM/YYYY');
  return timestamps.filter((timestamp) => {
    const timestampFormat = dayjs(timestamp);
    return formatDate(timestampFormat, 'MM/YYYY') === selectedMonthFormat;
  });
};

export const getBoundsMinMax = (bounds) => {
  const latitudes = bounds?.map((coord) => coord[0]);
  const longitudes = bounds?.map((coord) => coord[1]);
  const minLat = Math.min(...latitudes);
  const maxLat = Math.max(...latitudes);
  const minLng = Math.min(...longitudes);
  const maxLng = Math.max(...longitudes);

  return [[minLat, minLng], [maxLat, maxLng]];
};

export const setUniqueList = (arr, key) => (
  [...new Map(arr?.map((item) => [item[key], item])).values()]
);

export const setArrayOfUniqueItems = ({ data, type }) => {
  const uniqueItemSet = new Set();
  data?.forEach((item) => {
    if (type === 'agencies') {
      uniqueItemSet.add(item.agencyName);
    }
    if (type === 'phone') {
      uniqueItemSet.add(item.telephoneNumber);
    }
  });
  const uniqueItem = Array.from(uniqueItemSet);
  return uniqueItem;
};

export const showErrorNotification = (message, description) => {
  notification.error({
    message,
    description,
    closable: true,
    duration: 2,
  });
};

export const handleLogout = (dispatch, navigate) => {
  dispatch(logoutAction.request());
  navigate('/');
};

export const handleStepChange = (setCurrentStep, direction) => {
  setCurrentStep((prevStep) => {
    if (direction === 'next') {
      return prevStep + 1;
    }
    return prevStep - 1;
  });
};

export const chartsData = [
  {
    id: 10,
    title: 'shared.chart.filteredDensity',
    dataKey: 'densityPower',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.power',
    borderColor: blueAzur2Color,
    backgroundColor: blueAzur2Color,
    type: ['all', 'analysis', 'densityPower'],
  },
  {
    id: 20,
    title: 'shared.chart.mobileFilterCalibrationHigh',
    dataKey: 'filterCalibrationHigh',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.power',
    borderColor: blueLight2Color,
    backgroundColor: blueLight2Color,
    type: ['all', 'filterCalibrationHigh'],
  },
  {
    id: 30,
    title: 'shared.chart.mobileFilterCalibrationDesc',
    dataKey: 'filterCalibrationDesc',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.power',
    borderColor: blueLightColor,
    backgroundColor: blueLightColor,
    type: ['all', 'filterCalibrationDesc'],
  },
  {
    id: 35,
    title: 'shared.chart.temporalContinuityVector',
    dataKey: 'temporalContinuityVector',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.power',
    borderColor: blueLightColor,
    backgroundColor: blueLightColor,
    type: ['all', 'temporalContinuityVector'],
  },
];

export const renderTags = (key, icon, text, color = '#8ADFC1') => (
  <Tag className="tag" title={text} key={key} icon={icon} color={color}>
    {text}
  </Tag>
);

export const calibrationCount = (mobilesList, type) => mobilesList?.filter((mobile) => (type === 'inProcess'
  ? mobile.calibrationSoundCount > 0 && mobile.calibrationSoundCount < 20
  : mobile.calibrationSoundCount === 20)).length;

export const renderCalibrationStatus = (calibration) => {
  if (calibration === 20) {
    return <CheckOutlined className="green" />;
  }
  if (calibration === 0) {
    return <CloseOutlined className="red" />;
  }
  const percentage = (calibration / 20) * 100;
  return `${percentage.toFixed(0)}%`;
};

export const renderDecisionAlgo = (text, t) => {
  let span;
  switch (text) {
    case 10:
      span = <span className="orange">{t('records.suspectedLeak')}</span>;
      break;
    case 1:
      span = <span className="red">{t('records.leak')}</span>;
      break;
    default:
      span = <span>{t('records.noLeak')}</span>;
  }
  return span;
};

export const getTriplets = (decisionPSGlobal, decisionPSBlock, decisionCnn) => {
  if (decisionPSGlobal === null && decisionPSBlock === null && decisionCnn === null) return '-';
  return `${decisionPSGlobal}${decisionPSBlock}${decisionCnn}`;
};

export const getStatusClass = (statusCode) => {
  switch (statusCode) {
    case 1:
    case 31:
    case 40:
      return 'orange';
    case 2:
    case 32:
    case 33:
      return 'red';
    case 100:
      return 'lightgrey';
    default:
      return 'green';
  }
};

export const getStatusText = (statusCode, t) => {
  const status = {
    0: t('licence.active'),
    1: t('licence.inactive'),
    2: t('licence.blocked'),
    10: t('orders.quoteSent'),
    11: t('orders.quoteSigned'),
    20: t('orders.orderSent'),
    21: t('orders.orderValidated'),
    22: t('orders.invoiceSent'),
    30: t('orders.orderInProgress'),
    31: t('orders.orderAboutToBeFinished'),
    32: t('orders.orderFinished'),
    33: t('orders.orderBlocked'),
    40: t('orders.renewalRequestSent'),
    41: t('orders.renewalAccepted'),
    100: t('orders.orderAbandoned'),
  };
  return status[statusCode] || '--';
};

export const getTagText = (selectedTag, t) => {
  const tags = {
    0: t('records.tags.untreated'),
    1: t('records.tags.processed'),
    2: t('records.tags.beingProcessed'),
    3: t('records.tags.underRepair'),
    4: t('records.tags.repaired'),
    5: t('records.tags.falsePositive'),
    6: t('records.tags.falseNegative'),
    7: t('records.tags.toVerify'),
    8: t('records.tags.verified'),
  };
  return tags[selectedTag] || '--';
};

export const getTagColor = (selectedTag) => {
  const tags = {
    0: greyColor,
    1: greenLightColor,
    2: paleGreenColor,
    3: paleGreenColor,
    4: greenLightColor,
    5: blueColor,
    6: violetColor,
    7: violetColor,
    8: greenLightColor,
  };
  return tags[selectedTag] || 'grey';
};

const commonActionButtons = (t) => [
  { id: 5, title: t('orders.block'), icon: <StopOutlined className="red" /> },
  { id: 10, title: t('orders.edit'), icon: <EditOutlined className="blue-azure" /> },
];

export const orderActionButtons = (t) => [
  ...commonActionButtons(t),
  { id: 15, title: t('orders.renewal'), icon: <SyncOutlined className="orange" /> },
];

export const licenceActionButtons = (t) => [
  ...commonActionButtons(t),
];

export const renderActionsButtons = (buttons) => (
  buttons.map((button) => (
    <ActionButton key={button.id} title={button.title} icon={button.icon} />
  ))
);

export const preventInvalidNumberInput = (event, min, max) => {
  const { data, target: { value } } = event;
  const newValue = value + data;

  const isNumericInput = regexNumber.test(data);
  const newValueAsNumber = parseInt(newValue, 10);
  const isWithinRange = newValueAsNumber >= min && newValueAsNumber <= max;

  const isMultipleZeros = multipleZeros.test(newValue);

  const isInvalidInput = !isNumericInput || !isWithinRange || isMultipleZeros;

  if (isInvalidInput) {
    event.preventDefault();
  }
};

export const getTagIcon = (tag) => {
  switch (tag) {
    case 0:
      return <StopOutlined />;
    case 1:
      return '...';
    case 2:
      return <SyncOutlined />;
    case 3:
      return <ToolOutlined />;
    case 4:
      return <CheckCircleOutlined />;
    case 5:
      return <PlusCircleOutlined />;
    default:
      return <MinusCircleOutlined />;
  }
};
